body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root,[data-amplify-theme]{
  --amplify-components-authenticator-max-width: none;
  --amplify-colors-teal-100: #146b93;
  --amplify-colors-teal-90: #177aa8;
  --amplify-colors-teal-80: #29A7E1;
  --amplify-colors-teal-60: #3bafe4;
  --amplify-colors-teal-40: #65c0ea;
  --amplify-colors-teal-20: #8fd1f0;
  --amplify-colors-teal-10: #d3edf9;
}

[data-amplify-authenticator][data-variation=modal] {
  background-image: url(login_logo.jpg);
  background-repeat: no-repeat
}

.alert-info {
  color: #0d445d;
  background-color: #d2edf9;
  border-color: #b3e0f4;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #bee5eb;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}